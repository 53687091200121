
import { Component, Vue } from "vue-property-decorator";
import {
  UsageApi,
  UsageRecord,
  FindUsageQuantityEnum
} from "@fundraisingbureauet/admin-typescript-client";
import { AuthInterceptor } from "@/config";
import { eventHub } from "@/router";
import ComponentUsageRecord from "@/components/invoice/UsageRecord.vue";
import TextInputBlock from "@/components/shared/inputs/TextInputBlock.vue";
import Pagination from "@/components/shared/Pagination.vue";

@Component({
  components: { ComponentUsageRecord, TextInputBlock, Pagination }
})
export default class UsageRecords extends Vue {
  usageRecords?: UsageRecord[] | null = null;
  searchCustomerString = "";
  usageRecordsLoading = false;
  usageRecordsLoaded = false;
  usageRecordsErrored = false;

  get getUsageRecords() {
    return this.usageRecords;
  }
  set getUsageRecords(value) {
    this.usageRecords = value;
  }

  /**
   * Initialise
   */
  created() {
    this.loadUsageRecords();
  }

  private loadUsageRecords() {
    this.usageRecordsLoading = true;

    new UsageApi(AuthInterceptor.Instance)
      .findUsage({ quantity: FindUsageQuantityEnum.UNINVOICED })
      .subscribe(
        response => {
          this.usageRecordsLoading = false;
          this.usageRecordsLoaded = true;

          this.getUsageRecords = response;
        },
        error => {
          eventHub.$emit("httpError", error);
          this.usageRecordsLoading = false;
          this.usageRecordsErrored = true;
          this.usageRecordsLoaded = true;
        }
      );
  }
}
